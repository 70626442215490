import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { AllStages, ApproveTransaction, GetAllTransationNowPayment, GetTransactionById, GetUserDetail, TransationOfUser } from "../Api's";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "../Constants";
import { SwapCalls } from "@mui/icons-material";
import Swal from "sweetalert2";
import { Notifications } from "../Components/Toast";


const TransactionDetails = () => {
  
  const [transactionspayment, setTransactionspayment] = useState();
  const navigate = useNavigate();
  const [UserDetail, setUserDetail] = useState();
  const location = useLocation();
  const { id } = location.state.transactionObject || {};
  const [ transactionObject ,settransactionObject] = useState({})
  const [allstages, setallstages] = useState();
  // const { id }  = useParams()


  useEffect(()=>{


    GetTransactionById(id).then((data)=>{
      console.log(data.data,'data' )
      settransactionObject(data.data )
      // settransactionObject(data)
    }).catch(()=>{

    })

  },[id])

  useEffect(() => {
    if (transactionObject) {
      GetUserDetail(transactionObject?.account_id)
        .then((user) => {
          setUserDetail(user);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });

      AllStages(100, 1)
        .then((data) => {
          setallstages(data.data);
        })
        .catch((error) => {
          console.error("Error fetching stages:", error);
        });
    }
  }, [transactionObject]);

  const GetActiveStage = async () => {
    try {
      const id = transactionObject?.payment_id;
      if (id) {
        const Active = await GetAllTransationNowPayment(id);
        setTransactionspayment(Active);
      }
    } catch (error) {
      console.error("Error fetching active stage:", error);
      // Notifications("error", "not found");
    }
  };

  useEffect(() => {
    if (transactionObject) {
      GetActiveStage();
    }
  }, [transactionObject]);

  const ApprovetransactionFun = (id) => {
    ApproveTransaction(id).then(() => {
      Notifications('success', 'Transaction Approve successfully') 
      window.location.reload();
    }).catch(() => {

    })
  }

  return (
    <section className="admin-panel container-fluid">
      <Row>
        <Container>
          <Row>
            <Col xl="12" lg="12" md="12">
              <div className="white-post">
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <div className="flex-div-xs align-items-center">
                      <h4 className="color font-weight-bolder">
                        Transaction Details{" "}
                        <span className="getid">
                          {" "}
                          {">"} {transactionObject?.transaction_number}
                        </span>
                      
                      </h4>
                 <div >
                      <Button
                        className="btnback mr-2"
                        onClick={() =>  {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, Approve it!"
                          }).then((result) => {
                            if (result.isConfirmed) {
                              ApprovetransactionFun(   transactionObject?.transaction_number ) 
                              Swal.fire({
                                title: "Approved!",
                                text: "Transactions confirmed",
                                icon: "success"
                              });
                            }
                          })
                        
                        
                        }
                        
                        
                        }
                      >
                        Approve 
                      </Button>

                      <Button
                        className="btnback mr-2"
                        onClick={() => navigate("/Transactions")}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Back
                      </Button>
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="spacer-30"></div>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="data-details">
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                Transaction Date
                              </span>
                              <span className="data-details-info">
                                {
                               transactionObject?.created_at
                                }
                              </span>
                            </Card.Title>
                          </div>
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                Transaction Status
                              </span>
                              {/* <span className="color-badge">Pending</span> */}
                              <span
                                className={`badge ${transactionObject?.status === "Approved"
                                    ? "color-badge ml-2"
                                    : "badge-danger"
                                  }`}
                              >
                                {transactionObject?.status}

                              </span>

                            </Card.Title>
                          </div>
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">Transaction by</span>
                              <span className="data-details-info">{transactionObject?.byadmin ? 'Generated By Admin' : 'Generated By User'}</span>
                            </Card.Title>
                          </div>
                          <div className="fake-class">
                            <Card.Title>
                              <span className="viewdetail">
                                Transaction Note
                              </span>
                              <span className="data-details-info">
                                Not Reviewed yet.
                              </span>
                            </Card.Title>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <h6 className="card-sub-title ">TRANSACTION INFO </h6>
                    <ul className="transaction-container">
                      <li>
                        <h6>Transaction Type</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <b>{transactionObject?.type}</b>
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Payment Gateway</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            Now Payment
                            {/* <b>Manual</b>- Offline Payment-- */}
                          </p>
                          <b>  </b>
                        </div>
                      </li>
                      <li>
                        <h6>Payable Amount</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {transactionObject?.amount_in_crypto}
                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Selected Currency </h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            {transactionObject?.selected_crypto?.toUpperCase()}


                            {/* <b>0 LTC</b> */}

                          </p>
                        </div>
                      </li>
                      <li>
                        <h6>Amount in Dollors </h6>
                        <div className="transaction-txt-cntnr">
                          <p>{transactionObject?.amount_in_dollars}</p>
                        </div>
                      </li>
                      {
                        transactionObject?.type === 'Purchase' ?
                          <>
                            <li>
                              <h6>To Wallet Address </h6>
                              <div className="transaction-txt-cntnr">
                                <p>{transactionObject?.to_wallet_address}</p>
                              </div>
                            </li>
                            <li>
                              <h6>Payment Created Date </h6>
                              <div className="transaction-txt-cntnr">
                                <p>
                                  {console.log("ddd", transactionObject)}

                                  {/* {
                                  formatDate(transactionObject?.payment_created_date).split(
                                    ", "
                                  )[0]
                                }
                                {","}
                                {
                                  formatDate(transactionObject?.payment_created_date).split(
                                    ", "
                                  )[1]
                                }{" "} */}

                                  {
                                    transactionObject?.payment_created_date
                                      ? `${formatDate(transactionObject?.payment_created_date)?.split(", ")[0]}, ${formatDate(transactionObject?.payment_created_date)?.split(", ")[1]} ${formatDate(transactionObject?.payment_created_date)?.split(", ")[2]}`
                                      : "--"
                                  }
                                </p>
                              </div>
                            </li>
                          </>
                          : <>
                            <li>
                              <h6>From Transaction Number </h6>
                              <div className="transaction-txt-cntnr">
                                <p>{transactionObject?.to_wallet_address}</p>
                              </div>
                            </li>
                          </>


                      }

                    </ul>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <h6 className="card-sub-title ">TOKEN DETAILS </h6>
                    <ul className="transaction-container">
                      <li>
                        <h6>Stage Name</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <b>{allstages?.find((item) => item.id == transactionObject?.stage_id)?.level}</b>
                          </p>
                        </div>
                      </li>

                      {
                        transactionObject?.type === 'Purchase' &&
                        <>
                          <li>
                            <h6>Token Purchase </h6>
                            <div className="transaction-txt-cntnr">
                              <p>
                                <b>{transactionObject?.token_purchased}</b> ALGT
                              </p>

                            </div>
                          </li>
                          <li>
                            <h6>Bonus Tokens </h6>
                            <div className="transaction-txt-cntnr">
                              <p>
                                <b>{transactionObject?.bonus_tokens ?? 0}</b> ALGT
                              </p>
                            </div>
                          </li>
                        </>
                      }

                      <li>
                        <h6>Total Token</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <b>{parseInt(transactionObject?.token_purchased) + parseInt(transactionObject?.bonus_tokens ?? 0)}</b> ALGT
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>

                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <h6 className="card-sub-title ">USER DETAILS </h6>
                    <ul className="transaction-container">
                      <li>
                        <h6>Name</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                            <b>{UserDetail?.name}</b>
                          </p>
                        </div>
                      </li>



                      <li>
                        <h6>Email</h6>
                        <div className="transaction-txt-cntnr">
                          <p>
                          <b>{UserDetail?.email}</b>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </Col>

                </Row>

                {
                  transactionObject?.type === 'Purchase' && transactionObject?.payment_id &&
                  <Row>
                    <Col xl="12" lg="12" md="12">
                      <h6 className="card-sub-title "> NOW PAYMENT DETAILS </h6>
                      
                      <ul className="transaction-container">
                      <li>
                          <h6>Status on Now Payment</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.payment_status}</b>
                            </p>
                          </div>
                        </li>
                        <li>
                          <h6>Price Amount</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.price_amount}</b>
                            </p>
                          </div>
                        </li>

                        <li>
                          <h6>Price Currency</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.price_currency}</b>
                            </p>
                          </div>
                        </li>

                        <li>
                          <h6>Pay Amount</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.pay_amount}</b>
                            </p>
                          </div>
                        </li>

                        <li>
                          <h6>Actually  Paid</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.actually_paid}</b>
                            </p>
                          </div>
                        </li>

                        <li>
                          <h6>Pay Currency</h6>
                          <div className="transaction-txt-cntnr">
                            <p>
                              <b>{transactionspayment?.pay_currency}</b>
                            </p>
                          </div>
                        </li>



                      </ul>
                    </Col>

                  </Row>
                }

              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </section>
  );
};

export default TransactionDetails;
