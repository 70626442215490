import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Dropdown,
  Tabs,
  Tab,
  Modal,
  Form,
} from "react-bootstrap";
import icontransaction from "../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../Assets/img/admin-icons/icon-eye.png";
import iconi from "../Assets/img/admin-icons/icon-i.png";
import { Notifications } from "../Components/Toast";
import {
  AllStages,
  AllTransationShow,
  AllUserShow,
  CreateIco,
  GetSaleInStage,
  GetToekenSumofWeek,
} from "../Api's";
import { addCommas, formatDate } from "../Constants";
import Loader from "./Loader";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { PulseLoader } from "react-spinners";
import StageCard from "../Components/StageCard";
import Pagination from "../Components/Pagination";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      className="admin-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Button className="close-btn" onClick={props.onHide}><i className="fa fa-close"></i></Button>
        <h6>
          Login Activities <i className="fa fa-angle-right"></i>{" "}
          <span>UD09525</span>
        </h6>
        <div className="text-info">
          <p className="m-0">Login with Chrome in OS X-10_15_7 via 172.70.55.74</p>
          <p className="m-0">04 Jan, 2024 12:16 AM</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function IcoStage() {
  const [modalShow, setModalShow] = React.useState(false);
  const [transactions, setTransactions] = useState([]);
  const [stages, setStages] = useState([]);
  const [percentage, setPercentage] = useState();
  const [activeStageIndex, setActiveStageIndex] = useState(null);
  const [balances, setBalances] = useState([]);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 9;
  const [isLoading, setIsLoading] = useState(false);
  const [UpdateModal, setUpdateModal] = useState(false)
  const [formData, setFormData] = useState({
    levelNumber: '',
    priceOfToken: '',
    totalLimit: '',
    futureTokenPrice: '',
    minimumPurchase: '',
    maximumPurchase: '',
  });
  // const [dates,setdates]=useState({
  //   startDate: '',
  //   endDate: '',
  // })
  const [loading, setLoading] = useState(true);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value


    setFormData((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));
  };

  // const handeldate=(e)=>{
  //   const {name,value}=e.target;

  //   setdates((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // }

  const GetActiveStage = async (page) => {
    setLoading(true)
    try {
      const Active = await AllStages(itemsPerPage, page);
      setStages(Active?.data);
      setTotalItems(Active?.totalItems);
      const activeIndex = Active?.data?.findIndex(
        (stage) => stage?.currently_active
      );
      setActiveStageIndex(activeIndex);
      const idArray = Active?.data?.map((item) => item?.id) || [];

      // Save the idArray state
      setTransactions(idArray);
      setLoading(false)
    } catch (error) {
      // Notifications("error", "not found");
      setLoading(false)
    }
  };

  const fetchBalances = async () => {
    try {
      const balancesData = await Promise.all(
        transactions.map(async (stageId) => {
          const balance = await GetSaleInStage(stageId);

          return balance?.data?.token_purchased || 0;
        })
      );
      setBalances(balancesData);
    } catch (error) {
      // Notifications("error", "not found");
    }
  };
  // useEffect(() => {
  //   GetActiveStage();
  // }, []);
  useEffect(() => {
    GetActiveStage(1);
    setCurrentPage(1);
  }, []);
  const handlePageChange = (page) => {

    GetActiveStage(page);
    setCurrentPage(page);
  };
  useEffect(() => {
    if (transactions.length > 0) {
      fetchBalances();
    }
  }, [transactions]);
  const handleClose = () => {
    setShow(false);
    setFormData({
      levelNumber: '',
      priceOfToken: '',
      totalLimit: '',
      futureTokenPrice: '',
      minimumPurchase: '',
      maximumPurchase: '',
    });
    // setdates({
    //   startDate: '',
    //   endDate: '',
    // })
  }
  const handleShow = () => setShow(true);

  const CreateStage = async () => {
    if (!formData?.levelNumber || !formData?.priceOfToken || !formData?.totalLimit || !formData?.futureTokenPrice || !formData?.maximumPurchase || !formData?.minimumPurchase) {
      Notifications('error', 'Please fill in all required fields');
      return;
    }
    setIsLoading(true);
    try {
      const requestData = {
        level: formData?.levelNumber,
        price_of_token: formData.priceOfToken,
        total_limit: formData?.totalLimit,
        futuretokenprice: formData?.futureTokenPrice,
        // startdate :dates?.startDate,
        // enddate:dates?.endDate,
        maximumpurchase: formData?.maximumPurchase,
        minimumpurchase: formData?.minimumPurchase
      };
      await CreateIco(requestData);
      Notifications('success', 'stage is created');
      setFormData({
        levelNumber: '',
        priceOfToken: '',
        totalLimit: '',
        futureTokenPrice: '',
        minimumPurchase: '',
        maximumPurchase: '',
      });
      // setdates({
      //   startDate: '',
      //   endDate: '',
      // })
      handleClose()
      GetActiveStage(1)


    } catch (error) {
      const errorMessage = error?.response?.data?.message;

      if (errorMessage === "Error : Validation error") {
        Notifications('error', 'Level already exists');
      } else {
        Notifications('error', 'stage is not created');
      }
      setIsLoading(false);

    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="white-post">
                  <div className="flex-div-sm align-items-center mb-4 ml-3">
                    <h4 className="color font-weight-bolder">
                      Available ICO/STO Stage
                    </h4>
                    <Button className="btnback mr-2" onClick={handleShow}>
                      <i className="fa fa-plus-circle"></i> Add Stage
                    </Button>
                    <Modal show={show} centered onHide={handleClose} animation={false} aria-labelledby="example-modal-sizes-title-lg" size="lg" className="admin-modal">
                      <Modal.Header>
                        <Modal.Title className="text-black">Create Stage </Modal.Title>
                      </Modal.Header>
                      <Modal.Body >

                        <Form>
                          {/* Name Field */}


                          {/* Level Number Field */}
                          <Form.Group as={Row} controlId="formLevelNumber">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Level Number
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter level number'
                                name="levelNumber"
                                value={formData.levelNumber}
                                onChange={handleInputChange}
                              />
                            </Col>
                          </Form.Group>

                          {/* Price of Token Field */}
                          <Form.Group as={Row} controlId="formPriceOfToken">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Price of Token
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter price of token'
                                name="priceOfToken"
                                value={formData.priceOfToken}
                                onChange={handleInputChange}
                              />
                            </Col>
                          </Form.Group>

                          {/* Total Limit Field */}
                          <Form.Group as={Row} controlId="formTotalLimit">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Total Limit
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter total limit'
                                name="totalLimit"
                                value={formData.totalLimit}
                                onChange={handleInputChange}
                              // Add necessary props or event handlers as needed
                              />
                            </Col>
                          </Form.Group>

                          {/* Future Token Price Field */}
                          <Form.Group as={Row} controlId="formFutureTokenPrice">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Future Token Price
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter future token price'
                                name="futureTokenPrice"
                                value={formData.futureTokenPrice}
                                onChange={handleInputChange}
                              // Add necessary props or event handlers as needed
                              />
                            </Col>
                          </Form.Group>

                          {/* Minimum Purchase Field */}
                          <Form.Group as={Row} controlId="formMinimumPurchase">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Minimum Purchase
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter minimum purchase'
                                name="minimumPurchase"
                                value={formData.minimumPurchase}
                                onChange={handleInputChange}
                              // Add necessary props or event handlers as needed
                              />
                            </Col>
                          </Form.Group>

                          {/* Start Date Field */}
                          {/* <Form.Group as={Row} controlId="formStartDate">
                <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                    Start Date
                </Form.Label>
                <Col xl="7" lg="8" md="6">
                    <Form.Control
                        type="date"
                        name="startDate"
                        value={dates.startDate}
                        onChange={handeldate}
                        // Add necessary props or event handlers as needed
                    />
                </Col>
            </Form.Group> */}

                          {/* End Date Field */}
                          {/* <Form.Group as={Row} controlId="formEndDate">
                <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                    End Date
                </Form.Label>
                <Col xl="7" lg="8" md="6">
                    <Form.Control
                        type="date"
                        name="endDate"
                        value={dates.endDate}
                        onChange={handeldate}
                        // Add necessary props or event handlers as needed
                    />
                </Col>
            </Form.Group> */}

                          {/* Maximum Purchase Field */}
                          <Form.Group as={Row} controlId="formMaximumPurchase">
                            <Form.Label column xl="4" lg="4" md="6" className='mt-2  label-black'>
                              Maximum Purchase
                            </Form.Label>
                            <Col xl="7" lg="8" md="6">
                              <Form.Control
                                type="text"
                                placeholder='Enter maximum purchase'
                                name="maximumPurchase"
                                value={formData.maximumPurchase}
                                onChange={handleInputChange}
                              // Add necessary props or event handlers as needed
                              />
                            </Col>
                          </Form.Group>

                          {/* Buttons */}
                          <Form.Group as={Row} controlId="formButtons">
                            <Col xl="4" lg="4" md="6">
                              {/* Empty column for spacing */}
                            </Col>
                            <Col xl="7" lg="8" md="6" className='m-text-center'>
                              <Button type='button' className="cancel-btn mr-3 mt-2" variant="secondary" onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button disabled={isLoading} className="btnback mr-3 mt-2" variant="primary" onClick={CreateStage}>
                                {isLoading ? <PulseLoader size={10} color="#fff" /> : 'Save'}
                              </Button>
                            </Col>
                          </Form.Group>
                        </Form>

                      </Modal.Body>
                    </Modal>
           
                  </div>
                  <Row>
                    {loading ? (
                      <>

                        <Col lg="12" sm="12">
                          <div colSpan="12" className="text-center">
                            <Loader animation="border" />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        {stages.map((stageData, index) => (
                          <StageCard stageData={stageData} index={index} setModalShow={setModalShow} GetActiveStage={GetActiveStage} />


                        ))}

                      </>
                    )}
                    <Col xl="12" lg="12" md="12">
                      {/* <div className="white-post"></div> */}
                      {stages?.length > 0 && (
                        <div className="text-center">
                          <Pagination
                            currentPage1={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            onPageChange={(page) => handlePageChange(page)}

                          />
                        </div>

                      )}
                      <div className="spacer-10"></div>
                    </Col>

                  </Row>

                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default IcoStage;
