import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import { CChart, CChartLine } from "@coreui/react-chartjs";
import { getStyle } from "@coreui/utils";
import {
  ActiveStage,
  AllTransation,
  AmountCollected,
  BonusTokenCount,
  GetCurrentStageTransactions,
  GetToekenSumofWeek,
  GraphRegistration,
  GraphTransation,
  PendingTokenCount,
  ReferralTokenCount,
  SumOfUser,
  getUserLastWeekCount,
} from "../Api's";
import { addCommas, addCommas1, formatDate } from "../Constants";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import Select from "react-select";
import { Notifications } from "../Components/Toast";
import { CircularProgressbar } from "react-circular-progressbar";
import Loader from "./Loader";

function Dashboard({ setShowFooter, setShowNavBar }) {
  // Hide the footer when the Login component mounts
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(true);
  }, [setShowFooter, setShowNavBar]);
  useEffect(() => {
    if (window.location.pathname === "/Dashboard") {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [balance, setbalace] = useState("");
  const [amount, setamount] = useState([]);
  const [totalsum, settotalsum] = useState("");
  const [activestage, setactivestage] = useState("");
  const [chartData, setChartData] = useState([]);
  const [num, setnum] = useState();
  const [transactions, setTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const priceValue = parseFloat(amount[2]?.sum_in_crypto);
  const [SaleinCurrentStage , setSaleinCurrentStage ] = useState (0)
  const [nums, setNums] = useState([]);
  const [pendingCount,setPendingCount]=useState()
  const [ReferralCount,setReferralCount]=useState()
  const [BonusCount,setBonusCount]=useState()
  const [UserLastWeekCount , setUSerLastWeekCount] = useState()
  const [selectedOption, setSelectedOption] = useState({
    label: "15 days",
    value: 2,
  });
  const formattedPrice = isNaN(priceValue)
    ? "--"
    : priceValue.toFixed(4).padStart(4, "0");
  const [selectedOption1, setSelectedOption1] = useState({
    label: "15 days",
    value: 2,
  });
  const options = [
    { label: "7 days", value: 1 },
    { label: "15 days", value: 2 },
    { label: "30 days", value: 3 },
  ];
  const options1 = [
    { label: "7 days", value: 1 },
    { label: "15 days", value: 2 },
    { label: "30 days", value: 3 },
  ];

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    Graph(selected.value);
  };

  const handleSelectChange1 = (selected) => {
    setSelectedOption1(selected);
    Registration(selected.value);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const balance = await GetToekenSumofWeek();
      const { total_tokens_purchased } = balance?.data[0] || {};
      setbalace(total_tokens_purchased);
      setIsLoading(false);
    } catch (error) {
      Notifications("error", "not found");
      setIsLoading(false);

    }
  };
  const Amount = async () => {
    try {
      const Amount = await AmountCollected();
      const allamour=  Amount?.data || [];
      setamount(allamour); 
      
    } catch (error) {
      Notifications('error', "not found")

    }
  };
  const TotaleSumOfUser = async () => {
    try {
      const TotalSum = await SumOfUser();
      settotalsum(TotalSum?.data[0]);
    } catch (error) {
      Notifications("error", "not found");
    }
  };
  const GetActiveStage = async () => {
    try {
      const Active = await ActiveStage();
      setactivestage(Active);
    
    } catch (error) {
      Notifications("error", "not found");
    }
  };
  const Graph = async (selectedOption) => {
    try {
      const graph = await GraphTransation(selectedOption);
      const data = graph?.data || [];
      const dates = data.map((item) => {
        const date = new Date(item?.transaction_date);
        return new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          month: "short",
        }).format(date);
      });

      const nums = data?.map((item) => parseInt(item?.token_purchased, 10));
      setnum(nums);
      setChartData(dates);
    } catch (error) {
    }
  };
  const Registration = async (selectedOption1) => {
    try {
      const register = await GraphRegistration(selectedOption1);
      const grphregist = register?.data || [];
      const daysOfWeek = grphregist?.map((item) => {
        const date = new Date(item?.registration_date);
        const dayOfWeek = new Intl.DateTimeFormat("en-US", {
          weekday: "short",
        }).format(date);
        return dayOfWeek;
      });

      const numsData = grphregist?.map((item) =>
        parseInt(item?.users_added_count, 10)
      );
      setDaysOfWeek(daysOfWeek);
      setNums(numsData);
    } catch (error) {
    }
  };

  const Transaction = async () => {
    try {
      const allTransactionData = await AllTransation();
      const allTransactions = allTransactionData?.data || [];

      setTransactions(allTransactions);
      setDisplayedTransactions(allTransactions.slice(0, 5));
    } catch (error) {
    }
  };
  const handleViewMore = () => {
    setDisplayedTransactions(transactions);
    setShowAll(true);
  };
  useEffect(() => {
    GetActiveStage();
    Graph(selectedOption?.value);
    fetchData();
    Amount();
    TotaleSumOfUser();
    Transaction();
    Registration(selectedOption1?.value);
  }, [selectedOption1, selectedOption]);

  const option2 = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: daysOfWeek,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "Registration ",
        type: "bar",
        barWidth: "60%",
        data: nums, // Update the series data with nums
      },
    ],
  };
  const option3 = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["55%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "outside", 
          // position: "inside",
          formatter: "{b}\n{c}",
          textStyle: {
            color: "white",
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold", // This should be set to a valid value, e.g., "bold"
          },
        },
        labelLine: {
          show: true, 
        },
        startAngle: 80,
        data: [
          { value: balance, name: 'Total Sold', itemStyle: { color: '#00d285' }, label: { show: false } },
          { value: activestage?.total_limit - balance, name: 'Unsold Token', itemStyle: { color: '#ffc100' }, label: { show: false } }
        ]
      },
    ],
  };
  
  const option = {
    xAxis: {
      type: "category",
      data: chartData,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000) {
            return `${value / 1000}K`;
          }
          return value;
        },
      },
      splitLine: {
        show: true,
      },
      axisLabel: {
        formatter: (value) => {
          if (value >= 1000) {
            return `${value / 1000}K`;
          }
          return value;
        },

        interval: 10, // Adjust the interval value to change the gap between y-axis points
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
      formatter: (params) => {
        const dataIndex = params[0].dataIndex;
        const transactionCount = num[dataIndex];
        const formattedTransactionCount =
          transactionCount >= 1000
            ? `${transactionCount / 1000}K`
            : transactionCount;
        const date = chartData[dataIndex];
        return `Date: ${date}<br /> Token Sold: ${formattedTransactionCount}`;
      },
    },
    series: [
      {
        data: num,
        type: "line",
        smooth: true,
      },
    ],
  };

  useEffect(()=>{
    BonusTokenCount().then((data)=>{
      setBonusCount(data.total_tokens_purchased)

    }).catch(()=>{

    })
    ReferralTokenCount().then((data)=>{
      setReferralCount(data.total_tokens_purchased)

    }).catch(()=>{

    })
    PendingTokenCount().then((data)=>{
    setPendingCount(data.total_tokens_purchased)
    }).catch(()=>{

    })
    getUserLastWeekCount().then((data)=>{
      setUSerLastWeekCount(data.data[0].new_users_count)


    }).catch(()=>{

    })


    GetCurrentStageTransactions(activestage?.id).then((sale)=>{
      setSaleinCurrentStage(sale?.data?.total_tokens_purchased)
    }).catch(()=>{

    })

  },[activestage])
  // useEffect(()=>{
  //   BonusTokenCount()
  //   ReferralTokenCount()
  //   PendingTokenCount()
  // },[])

  const textStyle = { color: "black" };

  return (
    <>
        
      <section className="admin-panel container-fluid">
      {isLoading ? (
          <Col lg="12"  sm="12">
          <div colSpan="12" className="text-center mt-12">
            <div className="dashld">
            <Loader animation="border mt-5" />
            </div>
          </div>
        </Col>
        ) : (
        <Row>
          <Container>
            
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="card-container-main">
                  {/* Card */}
                  <CCard className="small-card">
                    <CCardBody>
                      <div style={textStyle}>
                        <h6>
                          TOKEN SALE -{" "}
                          {activestage?.level &&
                            activestage.level
                              .toUpperCase()
                              .replace(/(\D)(\d)/, "$1 $2")}
                        </h6>
                        <h3 className="font-weight-bold">
                          {activestage?.total_limit &&
                            addCommas(activestage.total_limit)}

                          <span>
                            {activestage?.total_limit &&
                              (
                                (SaleinCurrentStage / activestage.total_limit) *
                                100
                              ).toFixed(2)/1 }
                            %
                          </span>
                        </h3>
                        <div className="flex-div">
                          <p>
                            <b>{balance && addCommas1(balance)}</b> since last
                            week
                          </p>
                          <Link to="/IcoStage" className="simple-link purple">View</Link>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                  {/* Card */}
                  <CCard className="small-card">
                    <CCardBody>
                      <div style={textStyle}>
                        <h6>TOKEN USERS</h6>
                        <h3 className="font-weight-bold">
                          {totalsum?.sum}
                         
                        </h3>
                        <div className="flex-div">
                          <p>
                            <b>{UserLastWeekCount }</b> since last week
                          </p>
                          <Link to="/UserList" className="simple-link purple">View</Link>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                  {/* Card */}
                  <CCard className="small-card">
                    <CCardBody className="bg-card">
                      <div style={textStyle}>
                        <h6 className="yellow-color m-0">AMOUNT COLLECTED</h6>
                        <h3 className="font-weight-bold m-0">
                          {/* {amount[0]?.selected_crypto.toUpperCase()} */}
                        </h3>
                        {/* <span>USD</span> */}
                        <span></span> <br />
                        <div className="flex-div"  style={{display:'flex' , flexDirection:'row',overflowX:'scroll'}}>
                          {
                            amount?.map((item,index)=>{
                              if(item.selected_crypto !== 'admin')
                              return(
                              <div style={index===0 ?{}:{marginLeft:'40px'}}>
                              <p>
                                <b>{item.sum_in_crypto}</b> {item.selected_crypto.toUpperCase()}
                              </p>
       
                              </div>
                              )
                            

                            })
                          }

                      
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="12" md="12">
                <div className="card-container-main">
                  {/* Card */}
                  <CCard>
                    <CCardBody>
                      <div style={textStyle}>
                        <div className="flex-div">
                          <h6>Recent Transaction</h6>
                          {!showAll && (
                            <Link to="/Transactions" className="simple-link purple">View</Link>
                          )}
                        </div>
                        <div className="spacer-10"></div>
                        {displayedTransactions.map((transaction, index) => (
                          <ul className="card-trasaction-list">
                            <li key={index}>
                              <p>
                                <b>
                                  {transaction?.transaction_number
                                  }
                                </b>
                                {
                                  formatDate(transaction?.created_at).split(
                                    ", "
                                  )[0]
                                }
                                {","}
                                {
                                  formatDate(transaction?.created_at).split(
                                    ", "
                                  )[1]
                                }{" "}
                                <br />
                                {
                                  formatDate(transaction?.created_at).split(
                                    ", "
                                  )[2]
                                }
                                {/* 26 Dec, 2023 07:51 AM */}
                              </p>
                              <p>
                                <b>+  { parseFloat( transaction?.token_purchased).toFixed(2)}</b>
                                {transaction?.amount_in_crypto}{" "}
                                {transaction?.selected_crypto.toUpperCase()}
                              </p>
                              <span>
                              {transaction?.status ===
                                                  "Approved"  ? <i className="fa fa-check"></i> :
                                                  transaction?.status ===
                                                  "Rejected"  ? <i className="fa fa-times"></i> :
                                                  <i className="fa fa-clock-o"></i>
                                             
                                                }
                              </span>
                            </li>
                            {/* <li>
                        <p>
                          <b>TNX019191</b>
                          26 Dec, 2023 07:28 AM
                        </p>
                        <p>
                          <b>+4414</b>
                          1831.01 DOGE
                        </p>
                        <span>
                          <i className="fa fa-clock-o"></i>
                        </span>
                      </li> */}
                          </ul>
                        ))}
                      </div>
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                  {/* Card */}
                  <CCard className="mid">
                    <CCardBody>
                      <div className="flex-div" style={textStyle}>
                        <h6>Token Sale Graph</h6>
                        <div className="selected">
                          <Select
                            value={selectedOption}
                            onChange={handleSelectChange}
                            options={options}
                            isSearchable={false}
                          />
                        </div>
                      </div>

                      <ReactEcharts
                        style={{ height: 410 }}
                        className="Echarts"
                        echarts={echarts}
                        option={option}
                      />
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12">
                <div className="card-container-main">
                  {/* Card */}
                  <CCard>
                    <CCardBody>
                      <div className="flex-div" style={textStyle}>
                        <h6>Registration Statistics</h6>
                        <div className="selected">
                          <Select
                            value={selectedOption1}
                            onChange={handleSelectChange1}
                            options={options1}
                            isSearchable={false}
                          />
                        </div>
                      </div>
                      <ReactEcharts
                        className="Echarts"
                        echarts={echarts}
                        option={option2}
                        style={{ height: "210px" }}
                      />
                      {/* <div className="spacer-100"></div> */}
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                  {/* Card */}
                  <CCard className="mid big">
                    <CCardBody>
                      <div className="flex-container mid">
                        <div className="left-side">
                          <div style={textStyle}>
                            <h6>
                              Stage -{" "}
                              {activestage?.level &&
                                activestage.level
                                  .toUpperCase()
                                  .replace(/(\D)(\d)/, "$1 $2")}
                            </h6>
                            <div className="spacer-20"> </div>
                            <ul className="graph-list">
                              <li>
                                <span></span>
                                <b>Total</b>
                                <p>
                                  {" "}
                                  {activestage?.total_limit &&
                                    addCommas(activestage.total_limit)}
                                </p>
                              </li>
                              <li>
                                <span className="bg-green"></span>
                                <b>Sold</b>
                                <p>{ SaleinCurrentStage && addCommas(SaleinCurrentStage)}</p>
                              </li>
                              <li>
                                <span className="bg-red"></span>
                                <b>Sale %</b>
                                <p>
                                  {activestage?.total_limit &&
                                    (
                                      (SaleinCurrentStage / activestage.total_limit) *
                                      100
                                    ).toFixed(4)}
                                  % Sold
                                </p>
                              </li>
                              <li>
                                <span className="bg-brown"></span>
                                <b>Unsold</b>
                                <p>
                                  {activestage?.total_limit &&
                                    addCommas(
                                      activestage.total_limit - SaleinCurrentStage
                                    )}
                                </p>
                              </li>
                            </ul>
                            <p>* Not included</p>

                                              <p className="m-0">
                              *  Pending <b>{pendingCount}</b> token
                              sales.
                            </p>
                            <p className="m-0">
                              * Bonus  <b>{BonusCount}</b> token
                              sales.
                            </p>
                            <p className="m-0">
                              * Referral  <b>{ReferralCount}</b> token
                              sales.
                            </p>
                          </div>
                        </div>
                        <div className="right-side right-side-ME">
                 
                           <CircularProgressbar  
                           value={     ((SaleinCurrentStage / activestage.total_limit) * 100 )/100   } maxValue={1}    
                          //  {
                          //   // (
                          //   //   (saleInStage /
                          //   //     stageData?.total_limit) *
                          //   //   100
                          //   // ).toFixed(2) / 100
                          // }
                          text={`${
                            (
                                (SaleinCurrentStage /
                                activestage?.total_limit) *
                                100
                              ).toFixed(2) / 1
                          } %`} />
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                  {/* Card */}
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
        )}
      </section>
    
    </>
  );
}

export default Dashboard;
